import { createStore } from 'vuex'
import { Locale } from 'vant';
export default createStore({
  state: {
    lang: sessionStorage.getItem("lang") || "en-US",
    address:sessionStorage.getItem("address") ||""
  },
  getters: {
  },
  mutations: {
    setLang(state, data) {
      sessionStorage.setItem("lang", data);
      state.lang = data
     
    },
    setAddress(state, data) {
      sessionStorage.setItem("address", data);
      state.address = data
    },
  },
  actions: {
  },
  modules: {
  }
})
