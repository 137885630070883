<template>
 <router-view />
</template>
<script>
import { addNetwork,accountsChanged } from './utils/Function';
export default {
  name: 'App',
  components: {
  },
  async mounted() {
    if (window.ethereum) {
      accountsChanged()
    }
  },
  methods: {
    // async linkWallet() {

    // },
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
