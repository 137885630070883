import { createRouter, createWebHistory } from 'vue-router'
import { showToast } from 'vant';
import store from '@/store'
import i18n from "@/i18n";
const $t = i18n.global.t
const routes = [
  { path: "/", redirect: "/index" },
  {
    path: '/index',
    name: 'tab',
    component: () => import('../views/TabView.vue'),
    children: [
      {
        //理财
        path: "",
        name: "home",
        component: () => import("../views/HomeView"),
      },
      {
        //投资
        path: "invest",
        name: "invest",
        component: () => import("../views/InvestView"),
        meta: { requiresAddress: true }
      },
      {
        //团队
        path: "team",
        name: "team",
        component: () => import("../views/TeamView"),
        meta: { requiresAddress: true }
      },
    ]
  },
   // 切换语言
   {
    path: '/lang',
    name: 'lang',
    component: () => import( '../views/LangView.vue')
  },
  // 公告
  {
    path: '/notice',
    name: 'notice',
    component: () => import( '../views/NoticeView.vue')
  },
  // 公告详情
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component: () => import( '../views/NoticeDetailView.vue')
  },
   // 提币
   {
    path: '/extract',
    name: 'extract',
    component: () => import( '../views/ExtractView.vue'),
    meta: { requiresAddress: true }
  },
   // 提币记录
   {
    path: '/extractLog',
    name: 'extractLog',
    component: () => import( '../views/ExtractLogView.vue'),
    meta: { requiresAddress: true }
  },
   // 资产日志
   {
    path: '/assetLog',
    name: 'assetLog',
    component: () => import( '../views/AssetLogView.vue'),
    meta: { requiresAddress: true }
  },
  // 投资记录
  {
    path: '/InvestmentRecord',
    name: 'InvestmentRecord',
    component: () => import( '../views/InvestmentRecordView.vue'),
    meta: { requiresAddress: true }
  },
   // 投资详情
   {
    path: '/InvestmentDetails',
    name: 'InvestmentDetails',
    component: () => import( '../views/InvestmentDetailsView.vue'),
    meta: { requiresAddress: true }
  },
  // 客服
  {
    path: '/chat',
    name: 'chat',
    component: () => import( '../views/ChatView.vue'),
    meta: { requiresAddress: true }
  },
   // 签到
   {
    path: '/signin',
    name: 'signin',
    component: () => import( '../views/SigninView.vue'),
    meta: { requiresAddress: true }
  },
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // 检查路由的元数据字段
  if (to.meta.requiresAddress) {
    // 假设你的地址信息存储在某个全局状态或服务中
    const hasAddress = store.state.address; // 这个函数用于检查用户是否有地址

    if (!hasAddress) {
      // 如果用户没有地址，可以重定向到其他页面，或者返回登录页
      next('/'); // 这里你可以根据你的路由结构重定向到合适的页面
      showToast({
        message: $t('请连接钱包'),
        position: 'bottom',
      });
    }
  }

  // 如果用户有地址或者不需要地址，继续路由导航
  next();
});

export default router
