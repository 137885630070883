import { createApp } from 'vue';
import App from './App.vue';
import 'lib-flexible/flexible.js';
import router from './router';
import store from './store';
import GoEasy from 'goeasy'
import 'vant/lib/index.css';
import {
    Button,
    Cell,
    Tabbar,
    TabbarItem,
    Swipe, SwipeItem,
    Image as VanImage,
    Lazyload,
    NoticeBar,
    Col, Row, Field,
    CellGroup, Dialog,
    List, PullRefresh,
    Toast, showLoadingToast, Notify, Empty,
    Grid, GridItem,Icon,Popup,RollingText,Calendar,
    Highlight ,Switch,Sticky 
    // 其他你需要的组件
} from 'vant';
import './assets/css/style.css';
import { formdata, toFixedsNum } from './utils/index.js'
import i18n from "./i18n";
const app = createApp(App);
app.config.globalProperties.$formdata = formdata
app.config.globalProperties.$toFixedsNum = toFixedsNum
const goeasy = GoEasy.getInstance({
    host:"hangzhou.goeasy.io",  //若是新加坡区域：singapore.goeasy.io
    appkey:"BC-ea695998fe5f448e95ca02f5f4f89719",
    modules:['im']//根据需要，传入'im’或'pubusub'，或数组方式同时传入
});
app.provide('goEasy', goeasy) 
app.use(Button);
app.use(Cell);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Swipe);
app.use(SwipeItem);
app.use(VanImage);
app.use(Lazyload);
app.use(NoticeBar);
app.use(Col);
app.use(Row);
app.use(Field);
app.use(CellGroup);
app.use(Dialog);
app.use(List);
app.use(PullRefresh);
app.use(Toast);
app.use(showLoadingToast);
app.use(Notify);
app.use(Empty);
app.use(Grid);
app.use(Icon);
app.use(GridItem); 
app.use(Popup); 
app.use(Calendar); 
app.use(RollingText);  
app.use(Highlight); 
app.use(Switch);  
app.use(Sticky); 
app.use(store).use(router).use(i18n);
app.mount('#app')