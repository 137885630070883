import { Decimal } from 'decimal.js'
export function formdata(value,startdef=6, enddef=4) {
    if(value) {
        const start = value.slice(0, startdef);
        const end = value.slice(-enddef);
        return `${start}******${end}`;
    }
};

export function toFixedsNum(value,num=2) {
    value = Number(value);
    num = Number(num);
    if (value > 0) {
      var m = Math.floor(value * (10**num)) / (10**num);
      return parseFloat(m.toFixed(num));
    } else {
      value = 0;
      return parseFloat(value.toFixed(num));
    }
  };
// 加
export function jia (x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.plus(yy).toNumber()
}

// 减
export function jian (x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.minus(yy).toNumber()
}

// 乘
export function cheng (x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.mul(yy).toNumber()
}

// 除
export function chu (x, y) {
  if (!x) {
    x = 0
  }
  if (!y) {
    y = 0
  }
  const xx = new Decimal(x)
  const yy = new Decimal(y)
  return xx.div(yy).toNumber()
}

/**
 * 格式化日期
 * @prama t 时间戳
 * @return str MM-dd HH:mm
 */
export function formatDate(t) {
  t = t || Date.now();
  let time = new Date(t);
  let str = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
  str += '-';
  str += time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
  str += ' ';
  str += time.getHours();
  str += ':';
  str += time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
  return str;
};