
import { createI18n } from 'vue-i18n';
import store from '../store/index.js'
import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import zhTW from 'vant/es/locale/lang/zh-TW';
import zhCN from 'vant/es/locale/lang/zh-CN';
// 获取语言
const requireComponent = require.context(
    // 其组件目录的相对路径
    './lang',
    // 是否查询其子目录
    true,
    // 匹配基础组件文件名的正则表达式
    /[a-zA-Z]\w+\.(json)$/
)
let messages = new Object();
requireComponent.keys().forEach(fileName => {
    // 获取组件的PascalCase命名
    const componentName = fileName.split('/').pop().replace(/\.\w+$/, '');
    messages[componentName] = requireComponent(fileName);
})
const setLang = () =>{
    if(store.state.lang == 'en-US' ){
        return enUS
    }
    if(store.state.lang == 'zh-TW' ){
        return zhTW
    }
    if(store.state.lang == 'zh-CN' ){
        return zhCN
    }
}
Locale.use(store.state.lang, setLang());
// 语言注入
let i18n = createI18n({
    locale: store.state.lang,
    silentTranslationWarn: true,
    legacy: false,
    globalInjection: true, // 全局注册$t方法
    messages: messages,
    $t: (key) => {
        return this.messages[this.locale.value][key];
    }
})
export default i18n;