
import { ethers, Contract, BigNumber } from "ethers";
import { Erc20, Router } from "./ABIs";
import { createStore } from 'vuex';
import store from '@/store';
import { showToast, showLoadingToast, showNotify } from 'vant';
import { useRouter } from "vue-router";

import i18n from "@/i18n";
const $t = i18n.global.t
let provider, signer;
if (typeof window.ethereum !== 'undefined') {
    // MetaMask 或其他 Web3 浏览器扩展存在
    provider = new ethers.providers.Web3Provider(window.ethereum);
    signer = provider.getSigner(0);
} else if (typeof window.web3 !== 'undefined') {
    // 旧版 web3 API
    const web3Instance = new Web3(window.web3.currentProvider);
    provider = new ethers.providers.Web3Provider(web3Instance.currentProvider);
    signer = provider.getSigner();
} else {
    // 不是Web3环境，或者没有安装Metamask等插件
    console.error('Non-Ethereum browser detected. You should consider trying MetaMask!');
}
export { provider, signer };
// export const provider = new ethers.providers.Web3Provider(window.ethereum);
// export const signer = provider.getSigner(0);
const ttt_address = process.env.VUE_APP_TTTADDRESS
const usdt_address = process.env.VUE_APP_USDTADDRESS
const router_address = process.env.VUE_APP_ROUTERADDRESS
const message = "Login to Spectre"; // 你要签名的消息
// 检查 a 是否小于 b
export function isALessThanB(number1, number2) {
    const a = BigNumber.isBigNumber(number1) ? number1 : BigNumber.from(number1);
    const b = BigNumber.isBigNumber(number2) ? number2 : BigNumber.from(number2);
    return a.lt(b)
}
// 检查 a 是否大于 b
export function isABigB(number1, number2) {
    if (Number(number1) && Number(number2)) {
        const a = BigNumber.isBigNumber(number1) ? number1 : BigNumber.from(number1);
        const b = BigNumber.isBigNumber(number2) ? number2 : BigNumber.from(number2);
        return a.gt(b)
    }

}
// 检查 a 是否等于 b
export function isAEqualB(number1, number2) {
    const a = BigNumber.isBigNumber(number1) ? number1 : BigNumber.from(number1);
    const b = BigNumber.isBigNumber(number2) ? number2 : BigNumber.from(number2);
    return a.eq(b)
}

//钱包网络监听
export async function accountsChanged() {
    const router = useRouter()
    window.ethereum.on("accountsChanged", async function (accounts) {
        if (accounts[0]) {
            store.commit('setAddress', accounts[0]);
        } else {
            store.commit('setAddress', '')
        }
        // window.location.reload()
        router.replace({
            path: "/"
        })
    });
}
//钱包添加指定网络
export async function addNetwork() {
    window.ethereum.request({
        method: 'wallet_addEthereumChain', // Metamask的api名称
        params: [{
            chainId: "0x38", // 网络id，16进制的字符串
            chainName: "BSC主网", // 添加到钱包后显示的网络名称
            rpcUrls: [
                'https://bsc-dataseed1.binance.org/', // rpc地址
            ],
            iconUrls: [
                'https://testnet.hecoinfo.com/favicon.png' // 网络的图标，暂时没看到在哪里会显示
            ],
            blockExplorerUrls: [
                'https://bscscan.com/' // 网络对应的区块浏览器
            ],
            nativeCurrency: {  // 网络主币的信息
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18
            }
        }]
    })
}
//链接钱包
export async function connectWallet() {
    if (window.ethereum) {
        try {
            // 请求用户授权
            await window.ethereum.send('eth_requestAccounts');
            // 获取用户的地址
            const accounts = await provider.listAccounts();
            // 用户地址为空，说明没有链接钱包
            if (accounts.length === 0) {

            } else {
                const signature = await signer.signMessage(message);//签名失败说明为观察钱包
                // 如果需要，你可以进一步使用用户的地址执行其他操作
                if (signature) {
                    store.commit('setAddress', accounts[0]);
                } else {

                }
            }
        } catch (error) {
            showToast({
                message: $t('用户拒绝了授权或发生错误'),
                position: 'bottom',
            })
            store.commit('setAddress', '');
        }
    } else {
        showToast({
            message: $t('未检测到以太坊钱包'),
            position: 'bottom',
        })
    }
};

//获取USDT余额
export async function useErc20Balance(walletAddress) {
    const usdtContract = new ethers.Contract(usdt_address, Erc20, provider);
    try {
        const balance = await usdtContract.balanceOf(walletAddress);
        return ethers.utils.formatUnits(balance, 18);
    } catch (error) {
        console.error('Error fetching USDT balance:', error);
        return null;
    }
}
//获取授权余额
export async function useErc20Allowance(walletAddress, contractAddress) {
    const usdtContract = new ethers.Contract(usdt_address, Erc20, provider);
    try {
        const balance = await usdtContract.allowance(walletAddress, contractAddress ? contractAddress : router_address);
        return ethers.utils.formatUnits(balance, 18);
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
}
//发起授权
export async function approve(amount) {
    const usdt_contract = new ethers.Contract(usdt_address, Erc20, signer);
    try {
        return usdt_contract.approve(router_address, amount ? ethers.utils.parseUnits(amount, 18) : '115792089237316195423570985008687907853269984665640564039457584007913129639935')
    } catch (error) {
        showToast({
            message: $t('用户拒绝了授权或发生错误'),
            position: 'bottom',
        })
        // console.error('Error:', error);
        throw error;
    }
}

//路由合约里   获取上级
export async function team(walletAddress) {
    const routerContract = new ethers.Contract(router_address, Router, provider);
    try {
        const address = await routerContract.team(walletAddress);
        return address;
    } catch (error) {
        console.error('Error fetching USDT balance:', error);
        return null;
    }
}
//路由合约里  投资
export async function invest(amount, address) {
    const routerContract = new ethers.Contract(router_address, Router, signer);
    try {
        return routerContract.invest(ethers.utils.parseUnits(amount, 18), address);
    } catch (error) {
        console.error('Error fetching USDT balance:', error);
        showToast({
            message: $t('用户拒绝了授权或发生错误'),
            position: 'bottom',
        })
        console.error('Error:', error);
        throw error;
    }
}